.item_topindex
  display: block
  border-radius: 6px
  overflow: hidden
  position: relative
  box-shadow: $shadow-other
  .inner
    & > .wrapimg
      overflow: hidden
      & > .img_srcset
        width: 100%
        transition: transform .6s ease
    & > .content
      padding: 25px
      & > .title
        display: block
        color: $color-text-base
        font-size: 2rem
        font-weight: 700
        position: relative
        padding: 0 0 0 32px
        &::before
          position: absolute
          top: 5px
          left: 0
          content: ''
          background-image: url(@src/img/ic_arrow01.svg)
          background-repeat: no-repeat
          background-size: contain
          width: 22px
          height: 22px
      & > p.text
        color: $color-text-base
        font-size: 1.3rem
        margin-top: 6px
  &:hover
    // background-color: #fafafa
    .inner
      & > .wrapimg
        & > .img_srcset
          transform: scale(1.2,1.2)
      & > .content
        & > .title
          color: $color-text-link
