main.main_global.storybook
  margin: 0
  .index_outpatient
    margin-top: 30px
    & > .list
      +mq()
        padding-top: 5px
        padding-bottom: 5px
        display: flex
        justify-content: flex-start
        flex-wrap: wrap
        margin: -5px -7.5px
      & > .item
        margin: 10px 0
        +mq()
          flex: 0 0 240px
          margin: 5px 7.5px
  .item_indexoutpatient
    display: block
    background-color: #fff
    box-shadow: $shadow-other
    padding: 20px 0px 20px 16px
    // text-align: center
    width: 100%
    border-radius: 6px
    & > .title
      font-size: 2rem
      padding: 2px 2px 2px 28px
      position: relative
      color: $color-text-base
      font-weight: 700
      &.-small
        +mq()
          font-size: 1.6rem
          line-height: 2.9rem
      &::before
        position: absolute
        top: 7px
        left: 0
        content: ""
        background-image: url(@src/img/ic_arrow01.svg)
        background-size: contain
        background-repeat: no-repeat
        background-position: center
        width: 22px
        height: 22px
      & > .subtext
        display: inline
        +mq()
          font-size: 1.2rem
          margin-left: -6px
    &.-wide
      display: flex
      align-items: center
      padding: 20px 10px 20px 20px
      margin-top: 30px
      +mq()
        padding: 20px 10px 20px 40px
        margin-top: 40px
      & > .title
        white-space: nowrap
        +mq()
          white-space: wrap
      & > .list_unordered
        & > .list
          +mq()
            display: flex
            flex-direction: column
            flex-wrap: wrap
            height: 90px
            padding-left: 40px
          & > .item
            margin-left: 30px
            +mq()
              margin-left: 40px

