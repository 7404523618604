.button_tel
  display: flex
  align-items: center
  line-height: 1
  white-space: nowrap
  cursor: pointer
  font-family: 'Roboto', sans-serif
  font-weight: 700
  font-size: 2.6rem
  padding: 0px 4px 1px
  &.-colortextlink
    color: $color-text-link
    background-color: transparent
  &.-size_s
    font-size: 2.1rem
    letter-spacing: -0.02rem
  &.-icon
    display: flex
    align-items: center
    &::before
      display: block
      content: ''
      width: 24px
      height: 24px
      margin-right: 6px
      object-fit: contain
      content: url('@src/img/ic_tel.svg')
