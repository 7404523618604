=mq($breakpoint: 'screen and (min-width:560px)')
  @media #{$breakpoint}
    @content

=marker($color: $color-yellow)
  background: linear-gradient(transparent 45%, $color 45%, $color 80%, transparent 0%)

=reset-wysiwyg($target: '.class')
  .wysiwyg_editor
    #{$target}
      h1, h2, h3, h4, h5, h6, p, a,
      table, caption, tbody, tfoot, thead, tr, th, td
        margin: 0
        padding: 0
        border: 0
        font-size: 100%
        font: inherit
        line-height: normal
        vertical-align: baseline
        background: unset
        background-color: unset
        &::before
          content: unset
          display: unset

// アニメーション設定
=anime-setting($keyframes: showup, $delay: 0.3s, $duration: 1s, $iteration: 1)
  animation-name: $keyframes
  animation-delay: $delay
  animation-duration: $duration
  animation-fill-mode: both
  animation-iteration-count: $iteration

  @if $keyframes == showup
    +showup()
  @if $keyframes == showfade
    +showfade()

// 設定できる $keyframesのタイプ
=showup()
  opacity: 0
  transform: translateY(100px)
  filter: blur(5px)
  @keyframes showup
    0%
      opacity: 0
      transform: translateY(100px)
      filter: blur(5px)
    100%
      opacity: 1
      transform: translateY(0px)
      filter: blur(0px)

// 設定できる $keyframesのタイプ
=showfade()
  opacity: 0
  filter: blur(5px)
  @keyframes showfade
    0%
      opacity: 0
      filter: blur(5px)
    100%
      opacity: 1
      filter: blur(0px)
