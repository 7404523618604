.tab_contents
  & > .tab_head
    & > .ul
      display: flex
      justify-content: space-between
      align-items: stretch
      +mq()
        justify-content: flex-start
      & > .li
        flex: 0 0 80px
        display: flex
        +mq()
          flex: 0 0 262px
        &:not(:first-child)
          +mq()
            margin-left: 10px
        &.-current
          & > .tabbutton
            z-index: 1
            background-color: #fff
            &::after
              content: ''
              position: absolute
              bottom: -20px
              display: inline-block
              width: calc(100% + 30px)
              height: 20px
              background-color: #fff
        &.-current:first-child
          & > .tabbutton::after
            left: 0
            width: calc(100% + 20px)
        & > .tabbutton
          display: flex
          align-items: center
          justify-content: center
          width: 100%
          height: 100%
          outline: none
          border: none
          background-color: #fdfdfd
          padding: 10px 5px
          border-radius: 8px 8px 0 0
          box-shadow: $shadow-tab
          position: relative
          +mq()
            padding: 20px 8px
          & > .text
            font-size: 1.6rem
            text-align: left
            color: $color-text-link
            font-weight: 700
            line-height: 1.3
            +mq()
              font-size: 2rem
            .elp
              display: none
              +mq()
                display: inline
            br
              +mq()
                display: none
  & > .tab_body
    position: relative
    box-shadow: $shadow-tab
    background-color: #fff
    border-radius: 0 0 8px 8px
    +mq()
      border-radius: 0 8px 8px 8px
      overflow: hidden
    & > .content
      & > .tabarea
        display: none
        &.-current
          display: block
    & > .buttonbox
      padding: 20px 50px
      +mq()
        display: flex
        justify-content: center
