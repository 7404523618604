.nav_banner
  background-color: $color-subbrand
  padding: 35px 0 20px
  +mq()
    padding: 30px 0
    background-color: $color-subbrand2
  & > .wrap_single1100
    & > ul.list
      padding: 0
      display: flex
      flex-direction: column
      align-items: center
      +mq()
        flex-direction: row
        justify-content: space-between
      & > li.item
        flex: 0 1 auto
        padding: 5px 0px
        +mq()
          flex: 0 0 auto
