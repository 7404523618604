.footer_contents
  background-color: #F4FDFF
  background-size: 100% auto
  background-position: bottom 0 center
  background-repeat: no-repeat
  background-image: url("@src/img/img_footercontent_sp.jpg")
  padding: 60px 0
  +mq()
    background-size: cover
    background-position: center
    background-image: url("@src/img/img_footercontent_pc.jpg")
    padding: 100px 0
  & > .wrap_single1100
    margin: 0
    & > .inner
      +mq()
        display: flex
        padding: 23px
        justify-content: space-between
        background-color: #fff
        border-radius: 8px
        box-shadow: $shadow-other
      & > .block
        background-color: #fff
        border-radius: 8px
        padding: 7px
        box-shadow: $shadow-other
        +mq()
          box-shadow: none
        &:not(:first-child)
          margin-top: 20px
          +mq()
            margin-top: 0
        &.outpatient
          +mq()
            flex: 0 0 calc(450px + 14px)
        &.access
          +mq()
            flex: 0 0 calc(560px + 14px)
        & > .head
          padding: 20px 0
          text-align: center
          background: $color-subgradation
          border-radius: 6px 6px 0 0
          +mq()
            border-radius: 6px
          & > .title
            display: inline-block
            font-weight: 700
            font-size: 2rem
            & > .icon
              display: inline-block
              margin-right: 9px
        & > .content
          padding: 12px
          position: relative
          +mq()
            padding: 15px 0
          & > .dl
            display: flex
            padding: 5px 0
            & > .dt
              flex: 0 0 75px
              color: $color-mainbrand
              font-weight: 700
              font-size: 1.6rem
              +mq()
                flex: 0 0 85px
            & > .dd
              flex: 1 1 auto
              display: flex
              justify-content: flex-start
              font-weight: 400
              font-size: 1.6rem
              .textright
                text-align: right
                font-weight: 700
            &.vertical
              flex-wrap: wrap
              align-items: flex-start
              & > .dt
                text-align: center
                background-color: #FFF7F7
                flex: 0 0 90px
                padding: 3px 4px
              & > .dd
                flex: 0 0 100%
                padding: 4px 0px
                +mq()
                  flex: 1 1 auto
                  padding-left: 10px
          & > .map
            & > .inner
              iframe
                width: 100%
                height: auto
                +mq()
                  height: 219px
          & > .text
            font-size: 1.2rem
            line-height: 1.8rem
            color: $color-text-base
            padding: 5px 0
            +mq()
              padding-left: 85px
          & > .buttonbox
            margin-top: 15px
            +mq()
              display: flex
            &.-pos
              +mq()
                position: absolute
                bottom: 0
                right: 0
            & > .button_simple
              +mq()
                flex: 0 1 auto
              &:not(:first-child)
                margin-top: 10px
                +mq()
                  margin-top: 0
                  margin-left: 20px
