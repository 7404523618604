.nav_spfooterbar
  position: sticky
  bottom: 0
  width: 100%
  background-color: #fff
  z-index: calc(#{$zindex-header} - 1)
  box-shadow: $shadow-other
  +mq()
    display: none
  .inner
    display: flex
    .telbox
      flex: 0 0 calc(100% - 94px * 2)
      display: flex
      align-items: center
      justify-content: center
    .buttonbox
      flex: 0 0 auto
      display: flex
      .button_icon
        flex: 0 0 94px
        padding: 13px 0
        border-left: 1px solid $color-basegray
