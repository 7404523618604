.title_top
  .entitle
    font-size: 1.6rem
    letter-spacing: 0.05rem
    font-weight: 700
    font-family: 'Roboto'
    color: $color-mainbrand
  .title
    font-size: 2.6rem
    letter-spacing: 0.05rem
    font-weight: 700
    color: $color-text-base
    margin-top: 4px
    +mq()
      font-size: 3.2rem
      margin-top: 11px
