main.main_global.news,
main.main_global.news_notice,
main.main_global.news_closed_changed,
main.main_global.news_recruit
  margin: 0
  .list_news
    margin-top: 20px
    +mq()
      margin-top: 40px
    & > .list
      & > .item
        padding: 0
  .item_pagination
    margin-top: 35px
    +mq()
      margin-top: 40px

main.main_global.news_detail
  .wysiwyg_editor
    .newsinfo
      margin-top: 30px
      display: flex
      +mq()
        margin-top: 40px
      & > .date
        font-size: 1.4rem
        font-weight: 700
        letter-spacing: 0.02rem
        color: $color-text-base
        flex: 0 0 80px
        padding: 5px 0px 7px
      & > .category
        font-size: 1.4rem
        font-weight: 700
        letter-spacing: 0.02rem
        flex: 0 0 150px
        border-radius: 100px
        background-color: $color-subbrand
        color: $color-mainbrand
        padding: 5px 10px 7px
        text-align: center
        margin-left: 20px
    .wys_buttonbox
      margin-top: 55px
      +mq()
        margin-top: 90px
