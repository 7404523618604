a.button_icon
  display: inline-block
  line-height: 1
  white-space: nowrap
  color: $color-text-base
  &.-solid
    font-weight: 700
    font-size: 1.3rem
    padding: 10px 14px 11px
    border-radius: 8px
    background-color: #fff
    box-shadow: $shadow-other
    &.-size_l
      font-size: 1.5rem
  &.-flat
    font-weight: 700
    font-size: 1.3rem
    padding: 7px 18px 9px
    &.-colortextlink
      color: $color-text-link
      background-color: transparent
      &:hover
        +mq()
          background-color: rgba(0,0,0,.02)
  &.-icon
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    &::before
      display: inline-block
      content: ''
      margin-bottom: 6px
      background-size: contain
      background-repeat: no-repeat
    &.-contact::before
      width: 17px
      height: 17px
      background-image: url('@src/img/ic_w_contact.svg')
    &.-about_access::before
      width: 20px
      height: 20px
      background-image: url('@src/img/ic_map.svg')
    &.-time::before
      width: 20px
      height: 20px
      background-image: url('@src/img/ic_time.svg')
    &.-outpatient_index::before
      width: 20.5px
      height: 30.5px
      background-image: url('@src/img/ic_beginer.svg')
    &.-size_l
      &.-about_access::before
        width: 25.5px
        height: 27.7px
        margin-top: 1.4px
        margin-bottom: 6px + 1.4px
      &.-time::before
        width: 25.5px
        height: 25.5px
        margin-top: 2.5px
        margin-bottom: 6px + 5px
      &.-outpatient_index::before
        width: 20.5px
        height: 30.5px
