$height-header: var(--hvh)
$height-footer: var(--fvh)
$height-sidenav: var(--svh)
$height-main: calc((var(--vh, 1vh) * 100) - #{$height-header} - #{$height-footer})

$zindex-header: var(--zh, 100)

$color-link: #FF7F7F
$color-purple: #856AF4
$color-purple1: #8267EF
$color-green: #24A314
$color-green1: #23A21A
$color-green2: #F4F8F4
$color-green3: #C9FFC9
$color-yellow: #FFEC5D
$color-yellow1: #D98F01
$color-orange: #D98F01
$color-red: #DD0000
$color-red1: #BA2211
$color-white: #fff

$color-mainbrand: #FF7F7F
$color-subbrand: #fff4f4
$color-subbrand2: #FFEDED
$color-maingradation: linear-gradient(107.75deg, #FF7E7E 33.85%, #F35F5F 75.29%)
$color-maingradationhover: linear-gradient(107.75deg, #FF9898 33.85%, #FF8484 75.29%)
$color-subgradation: linear-gradient(104.25deg, #FFF6F6 41.75%, #FFEDED 79.2%)
$color-darkgray: #bbbbbb
$color-basegray: #e5e5e5
$color-lightgray: #fcfcfc
$color-lightgray1: #DFDFDF
$color-lightgray2: #F4F4F4
$color-lightgray3: #FAFAFA
$color-lightgray4: #DDDDDD
$color-lightgray5: #F8F8F8
$color-lightgray6: #F5F5F5
$color-black1: #222222
$color-pink: #FFF5F5
$color-lightblue: #F2F8FF

$color-attention: $color-red
$color-mailmagazine: #9B1281
$color-contact: #FFE200
// $color-text-black: #212121
// $color-text-dark: #212121
$color-text-base: #212121
$color-text-link: $color-link
$color-text-attention: $color-attention
$color-text-mailmagazine: $color-mailmagazine

$shadow-header: 0 0 20px  rgba(0,0,0,.03)
$shadow-tab: 0 0 40px  rgba(0,0,0,.06)
$shadow-button_l: 0 0 20px  rgba(0,0,0,.2)
$shadow-button_s: 0 0 10px  rgba(0,0,0,.2)
$shadow-other: 0 0 30px  rgba(0,0,0,.06)

$root2: 1.41421356

$button-icon-color-link: 'data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%226.7%22%20height%3D%2210.5%22%20viewBox%3D%220%200%206.7%2010.5%22%3E%20%3Cdefs%3E%20%3CclipPath%20id%3D%22clip-path%22%3E%20%3Crect%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_3933%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%203933%22%20width%3D%226.7%22%20height%3D%2210.5%22%20transform%3D%22translate(-4113.7%20-19282.25)%22%20fill%3D%22rgba(255%2C255%2C255%2C0.36)%22%2F%3E%20%3C%2FclipPath%3E%20%3C%2Fdefs%3E%20%3Cg%20id%3D%22%E3%83%9E%E3%82%B9%E3%82%AF%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_21%22%20data-name%3D%22%E3%83%9E%E3%82%B9%E3%82%AF%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%2021%22%20transform%3D%22translate(4113.7%2019282.25)%22%20clip-path%3D%22url(%23clip-path)%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_17348%22%20data-name%3D%22%E3%83%91%E3%82%B9%2017348%22%20d%3D%22M-4202.363-19926.982h6v6%22%20transform%3D%22translate(-15231.641%20-2219.219)%20rotate(45)%22%20fill%3D%22none%22%20stroke-linecap%3D%22round%22%20stroke-width%3D%222%22%20stroke%3D%22%230089ff%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E'
$button-icon-color-white: 'data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%226.7%22%20height%3D%2210.5%22%20viewBox%3D%220%200%206.7%2010.5%22%3E%20%3Cdefs%3E%20%3CclipPath%20id%3D%22clip-path%22%3E%20%3Crect%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_3933%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%203933%22%20width%3D%226.7%22%20height%3D%2210.5%22%20transform%3D%22translate(-4113.7%20-19282.25)%22%20fill%3D%22rgba(255%2C255%2C255%2C0.36)%22%2F%3E%20%3C%2FclipPath%3E%20%3C%2Fdefs%3E%20%3Cg%20id%3D%22%E3%83%9E%E3%82%B9%E3%82%AF%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_21%22%20data-name%3D%22%E3%83%9E%E3%82%B9%E3%82%AF%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%2021%22%20transform%3D%22translate(4113.7%2019282.25)%22%20clip-path%3D%22url(%23clip-path)%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_17348%22%20data-name%3D%22%E3%83%91%E3%82%B9%2017348%22%20d%3D%22M-4202.363-19926.982h6v6%22%20transform%3D%22translate(-15231.641%20-2219.219)%20rotate(45)%22%20fill%3D%22none%22%20stroke-linecap%3D%22round%22%20stroke-width%3D%222%22%20stroke%3D%22%23ffffff%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E'
$button-icon-color-purple: 'data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%226.7%22%20height%3D%2210.5%22%20viewBox%3D%220%200%206.7%2010.5%22%3E%20%3Cdefs%3E%20%3CclipPath%20id%3D%22clip-path%22%3E%20%3Crect%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_3933%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%203933%22%20width%3D%226.7%22%20height%3D%2210.5%22%20transform%3D%22translate(-4113.7%20-19282.25)%22%20fill%3D%22rgba(255%2C255%2C255%2C0.36)%22%2F%3E%20%3C%2FclipPath%3E%20%3C%2Fdefs%3E%20%3Cg%20id%3D%22%E3%83%9E%E3%82%B9%E3%82%AF%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_21%22%20data-name%3D%22%E3%83%9E%E3%82%B9%E3%82%AF%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%2021%22%20transform%3D%22translate(4113.7%2019282.25)%22%20clip-path%3D%22url(%23clip-path)%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_17348%22%20data-name%3D%22%E3%83%91%E3%82%B9%2017348%22%20d%3D%22M-4202.363-19926.982h6v6%22%20transform%3D%22translate(-15231.641%20-2219.219)%20rotate(45)%22%20fill%3D%22none%22%20stroke-linecap%3D%22round%22%20stroke-width%3D%222%22%20stroke%3D%22%239B1281%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E'
$button-icon-color-brand: 'data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%226.7%22%20height%3D%2210.5%22%20viewBox%3D%220%200%206.7%2010.5%22%3E%20%3Cdefs%3E%20%3CclipPath%20id%3D%22clip-path%22%3E%20%3Crect%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_3933%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%203933%22%20width%3D%226.7%22%20height%3D%2210.5%22%20transform%3D%22translate(-4113.7%20-19282.25)%22%20fill%3D%22rgba(255%2C255%2C255%2C0.36)%22%2F%3E%20%3C%2FclipPath%3E%20%3C%2Fdefs%3E%20%3Cg%20id%3D%22%E3%83%9E%E3%82%B9%E3%82%AF%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_21%22%20data-name%3D%22%E3%83%9E%E3%82%B9%E3%82%AF%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%2021%22%20transform%3D%22translate(4113.7%2019282.25)%22%20clip-path%3D%22url(%23clip-path)%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_17348%22%20data-name%3D%22%E3%83%91%E3%82%B9%2017348%22%20d%3D%22M-4202.363-19926.982h6v6%22%20transform%3D%22translate(-15231.641%20-2219.219)%20rotate(45)%22%20fill%3D%22none%22%20stroke-linecap%3D%22round%22%20stroke-width%3D%222%22%20stroke%3D%22%230A435D%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E'
$button-icon-color-green: 'data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%226.7%22%20height%3D%2210.5%22%20viewBox%3D%220%200%206.7%2010.5%22%3E%20%3Cdefs%3E%20%3CclipPath%20id%3D%22clip-path%22%3E%20%3Crect%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_3933%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%203933%22%20width%3D%226.7%22%20height%3D%2210.5%22%20transform%3D%22translate(-4113.7%20-19282.25)%22%20fill%3D%22rgba(255%2C255%2C255%2C0.36)%22%2F%3E%20%3C%2FclipPath%3E%20%3C%2Fdefs%3E%20%3Cg%20id%3D%22%E3%83%9E%E3%82%B9%E3%82%AF%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_21%22%20data-name%3D%22%E3%83%9E%E3%82%B9%E3%82%AF%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%2021%22%20transform%3D%22translate(4113.7%2019282.25)%22%20clip-path%3D%22url(%23clip-path)%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_17348%22%20data-name%3D%22%E3%83%91%E3%82%B9%2017348%22%20d%3D%22M-4202.363-19926.982h6v6%22%20transform%3D%22translate(-15231.641%20-2219.219)%20rotate(45)%22%20fill%3D%22none%22%20stroke-linecap%3D%22round%22%20stroke-width%3D%222%22%20stroke%3D%22%2323A21A%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E'
$button-icon-color-red: 'data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%226.7%22%20height%3D%2210.5%22%20viewBox%3D%220%200%206.7%2010.5%22%3E%20%3Cdefs%3E%20%3CclipPath%20id%3D%22clip-path%22%3E%20%3Crect%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_3933%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%203933%22%20width%3D%226.7%22%20height%3D%2210.5%22%20transform%3D%22translate(-4113.7%20-19282.25)%22%20fill%3D%22rgba(255%2C255%2C255%2C0.36)%22%2F%3E%20%3C%2FclipPath%3E%20%3C%2Fdefs%3E%20%3Cg%20id%3D%22%E3%83%9E%E3%82%B9%E3%82%AF%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_21%22%20data-name%3D%22%E3%83%9E%E3%82%B9%E3%82%AF%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%2021%22%20transform%3D%22translate(4113.7%2019282.25)%22%20clip-path%3D%22url(%23clip-path)%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_17348%22%20data-name%3D%22%E3%83%91%E3%82%B9%2017348%22%20d%3D%22M-4202.363-19926.982h6v6%22%20transform%3D%22translate(-15231.641%20-2219.219)%20rotate(45)%22%20fill%3D%22none%22%20stroke-linecap%3D%22round%22%20stroke-width%3D%222%22%20stroke%3D%22%23BA2211%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E'
$button-icon-color-purple1: 'data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%226.7%22%20height%3D%2210.5%22%20viewBox%3D%220%200%206.7%2010.5%22%3E%20%3Cdefs%3E%20%3CclipPath%20id%3D%22clip-path%22%3E%20%3Crect%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_3933%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%203933%22%20width%3D%226.7%22%20height%3D%2210.5%22%20transform%3D%22translate(-4113.7%20-19282.25)%22%20fill%3D%22rgba(255%2C255%2C255%2C0.36)%22%2F%3E%20%3C%2FclipPath%3E%20%3C%2Fdefs%3E%20%3Cg%20id%3D%22%E3%83%9E%E3%82%B9%E3%82%AF%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_21%22%20data-name%3D%22%E3%83%9E%E3%82%B9%E3%82%AF%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%2021%22%20transform%3D%22translate(4113.7%2019282.25)%22%20clip-path%3D%22url(%23clip-path)%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_17348%22%20data-name%3D%22%E3%83%91%E3%82%B9%2017348%22%20d%3D%22M-4202.363-19926.982h6v6%22%20transform%3D%22translate(-15231.641%20-2219.219)%20rotate(45)%22%20fill%3D%22none%22%20stroke-linecap%3D%22round%22%20stroke-width%3D%222%22%20stroke%3D%22%238267EF%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E'
$button-icon-color-yellow: 'data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%226.7%22%20height%3D%2210.5%22%20viewBox%3D%220%200%206.7%2010.5%22%3E%20%3Cdefs%3E%20%3CclipPath%20id%3D%22clip-path%22%3E%20%3Crect%20id%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2_3933%22%20data-name%3D%22%E9%95%B7%E6%96%B9%E5%BD%A2%203933%22%20width%3D%226.7%22%20height%3D%2210.5%22%20transform%3D%22translate(-4113.7%20-19282.25)%22%20fill%3D%22rgba(255%2C255%2C255%2C0.36)%22%2F%3E%20%3C%2FclipPath%3E%20%3C%2Fdefs%3E%20%3Cg%20id%3D%22%E3%83%9E%E3%82%B9%E3%82%AF%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97_21%22%20data-name%3D%22%E3%83%9E%E3%82%B9%E3%82%AF%E3%82%B0%E3%83%AB%E3%83%BC%E3%83%97%2021%22%20transform%3D%22translate(4113.7%2019282.25)%22%20clip-path%3D%22url(%23clip-path)%22%3E%20%3Cpath%20id%3D%22%E3%83%91%E3%82%B9_17348%22%20data-name%3D%22%E3%83%91%E3%82%B9%2017348%22%20d%3D%22M-4202.363-19926.982h6v6%22%20transform%3D%22translate(-15231.641%20-2219.219)%20rotate(45)%22%20fill%3D%22none%22%20stroke-linecap%3D%22round%22%20stroke-width%3D%222%22%20stroke%3D%22%23D98F01%22%2F%3E%20%3C%2Fg%3E%3C%2Fsvg%3E'
