.contact_main
  &:not(:first-child)
    margin-top: 30px
  & > .block
    background-color: #fff
    border-radius: 8px
    padding: 7px
    box-shadow: $shadow-other
    & > .head
      display: flex
      justify-content: center
      align-items: center
      padding: 10px 0
      min-height: 70px
      background: $color-subgradation
      border-radius: 6px 6px 0 0
      +mq()
        border-radius: 6px
      & > .title
        display: inline-block
        font-weight: 700
        color: $color-text-base
        text-align: center
        font-size: 1.8rem
        +mq()
          font-size: 2rem
        & > br
          +mq()
            display: none
    & > .content
      padding: 20px 12px
      position: relative

      // 以下 block 内のコンテンツ共有スタイル
      .twoblock
        +mq()
          display: flex
        & > .block
          +mq()
            flex: 0 0 50%
          &:not(:first-child)
            margin-top: 25px
            +mq()
              margin-top: 0
              border-left: 1px solid $color-basegray
      .buttoninfo
        margin-top: 12px
        & > .title
          font-size: 1.6rem
          text-align: center
          font-weight: 700
      .buttoninfo + .buttonbox
        margin-top: 10px
        +mq()
          margin-top: 25px
      .buttonbox
        display: flex
        justify-content: center
        & > .button_simple
          flex: 0 1 auto
      .telinfo
        margin-top: 12px
        & > .title
          font-size: 1.6rem
          text-align: center
          font-weight: 700
        & > .title + .tel
          margin-top: 10px
        & > .tel
          display: flex
          align-items: center
          justify-content: center
          flex-wrap: wrap
          +mq()
            flex-wrap: nowrap
          & > .icon
            display: inline-block
            width: 30px
            height: auto
            margin-top: -2px
            margin-right: 5px
          & > .text
            text-align: center
            font-weight: 700
            font-family: 'Roboto'
            display: inline-block
            background: $color-maingradation
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent
            font-size: 3.0rem
            +mq()
              font-size: 3.4rem
          & > .time
            margin-top: 5px
            font-size: 1.6rem
            line-height: 1.8
            color: $color-text-base
            flex: 0 0 100%
            text-align: center
            +mq()
              margin-top: 0
              margin-left: 50px
              flex: 0 0 auto
              text-align: left
        & > .infotime
          margin-top: 5px
          text-align: center
          color: $color-text-base
          font-size: 1.6rem
          line-height: 1.8
