.nav_megamenu
  flex: 0 0 auto
  & > .parentitem
    border-bottom: 4px solid #fff
    & > .link
      display: block
      color: $color-text-base
      font-weight: 700
      font-size: 1.8rem
      padding: 2px 17px 17px
    &:hover
      border-bottom: 4px solid $color-mainbrand
      & > .childlist
        display: block
    & > .childlist
      display: none
      position: absolute
      top: calc($height-header - 1.5px)
      left: 0
      width: 100%
      background: $color-subgradation
      & > .wrap_double1100
        display: flex
        align-items: center
        width: 100%
        padding-top: 45px
        padding-bottom: 45px
        & > .head
          flex: 0 0 300px
          & > a.headitem
            display: flex
            align-items: center
            font-size: 2rem
            font-weight: 700
            letter-spacing: 0.02rem
            color: $color-text-base
            &:before
              content: url('@src/img/ic_arrow01.svg')
              width: 22px
              height: 22px
              margin-right: 9px
            &:hover
              +mq()
                color: $color-text-link
        & > ul.list
          flex: 1 1 auto
          display: flex
          flex-direction: column
          flex-wrap: wrap
          height: 170px
          &.-min
            height: 35px
          & > li.item
            flex: 0 0 auto
            padding: 1px 70px 0 0
            & > a.link
              display: flex
              align-items: center
              color: $color-text-base
              font-weight: 400
              font-size: 1.6rem
              padding: 5px 0
              &:before
                content: url('@src/img/ic_arrow02.svg')
                margin-right: 13px
              &:hover
                +mq()
                  color: $color-text-link
  & > .parentitem:hover + .megamenu_overlay
    display: block
  .megamenu_overlay
    z-index: -1
    display: none
    content: ""
    position: absolute
    top: calc($height-header)
    left: 0
    width: 100%
    height: calc(100vh - #{$height-header})
    background-color: rgba(#ffffff,.7)
