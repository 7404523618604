.bread_crumbs
  display: none
  +mq()
    display: block
  & > .wrap_single1440
    & > .list
      display: flex
      justify-content: flex-end
      padding: 0 12px
      +mq()
        padding: 25px 40px
      & > .item
        position: relative
        line-height: 1
        &:first-child
          padding-left: 1px
          & > .link
            position: relative
            z-index: 1
            padding-left: 18px
          &::before
            content: url('@src/img/ic_home.svg')
            display: inline-block
            position: absolute
            top: 3px
            left: 0px
        &:not(:first-child)
          padding-left: 14px
          &::before
            content: '-'
            display: inline-block
            position: absolute
            top: 0px
            left:4px
            // transform: scale(0.5, 1.4)
            font-size: 1.6rem
            line-height: 1.8rem
            color: $color-basegray
        &:last-child
          min-width: 0
          overflow: hidden
        & > .link
          display: inline-block
          padding: 4px
          color: $color-text-base
          font-size: 1.3rem
          vertical-align: middle
          line-height: 1
          position: relative
          z-index: 1
          overflow: hidden
          white-space: nowrap
          text-overflow: ellipsis
          width: 100%
        & > a.link
          &:hover
            +mq()
              color: $color-text-link
        & > span.link
          color: $color-text-link
