.nav_side
  & > .block
    background-color: #fff
    border-radius: 8px
    padding: 7px
    box-shadow: $shadow-other
    & > .head
      display: flex
      justify-content: center
      align-items: center
      padding: 10px 0
      min-height: 60px
      background: $color-subgradation
      border-radius: 6px 6px 0 0
      +mq()
        border-radius: 6px
        min-height: 80px
      & > .icon
        max-width: 46px
        width: auto
        max-height: 46px
        height: auto
        display: inline-block
        margin-right: 4px
      & > .title
        display: inline-block
        font-weight: 700
        font-size: 2rem
        color: $color-text-base
        text-align: center
    & > .content
      padding: 12px
      position: relative
      & > ul.list
        & > li.item
          & > a.link
            position: relative
            display: block
            color: $color-text-base
            font-weight: 400
            font-size: 1.6rem
            padding: 8px 8px 8px 16px
            &:hover
              +mq()
                color: $color-text-link
            &.-active
              color: $color-text-link
            &::before
              position: absolute
              top: 7px
              left: 0px
              content: url(@src/img/ic_arrow02.svg)
              display: inline-block
            &.-innerpage
              &::before
                top: 9px
                transform: rotate(90deg)
