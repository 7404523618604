.item_pagination
  display: flex
  max-width: 100%
  overflow-x: auto
  justify-content: center
  & > .list
    display: flex
    position: relative
    padding-bottom: 70px
    +mq()
      padding-bottom: 0
    & > .item
      padding: 5px
      +mq()
        padding: 5px
      & > .link
        display: flex
        justify-content: center
        align-items: center
        min-width: 50px
        min-height: 50px
        padding: 2px 8px
        background-color: $color-subbrand
        border-radius: 8px
        color: $color-text-base
        font-size: 2rem
        font-weight: 700
        cursor: pointer
        &.-active
          color: $color-text-link
          cursor: default
          &:hover
            +mq()
              background-color: $color-subbrand
        &:hover
          +mq()
            color: $color-text-link
            background-color: lighten($color-subbrand,1%)
      & > .button_simple
        box-shadow: none
        +mq()
          margin: 0 10px
      &.-prev,
      &.-next
        position: absolute
        bottom: 0
        +mq()
          position: static
          bottom: unset
      &.-prev
        left: 0
      &.-next
        right: 0
