.nav_sp
  display: none
  z-index: 1
  &.-open
    display: block
  & > .inner
    position: fixed
    overflow-x: auto
    width: 100%
    top: $height-header
    height: calc((var(--vh, 1vh) * 100) - #{$height-header} )
    left: 0
    +anime-setting(showfade,0.01s,0.2s)
    background-color: $color-subbrand
    & > .list
      // z-index: 1
      display: flex
      justify-content: space-between
      align-items: stretch
      padding: 30px 18px
      height: calc(90px + 60px)
      & > .button_icon
        flex: 0 0 106px
    & > .navgroup
      margin: 0 20px
      border-bottom: solid 1px #fff
    & > .contactgroup
      background-color: $color-subbrand
      & > .nav_contact
        & > .list
          flex-direction: column-reverse
          align-items: center
          justify-content: center
          padding: 15px 0 25px
          & > .item
            padding: 15px 0
            & > .button_tel
              font-size: 2.8rem
            & > .button_simple
              font-size: 1.8rem
              padding: 19px 30px 20px
