body
  margin: 0
  padding: 0
  border: 0
  font-size: 100%
  font: inherit
  vertical-align: baseline

div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video
  margin: 0
  padding: 0
  border: 0
  font-size: 100%
  font: inherit
  line-height: normal
  vertical-align: baseline

*, *::before, *::after
  box-sizing: border-box

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section
  display: block

ol, ul
  list-style: none

blockquote:before, blockquote:after,
q:before, q:after
  content: ''
  content: none

table
  border-collapse: collapse
  border-spacing: 0

input,
select,
textarea
  background: none
  background-color: transparent
  border: none
  outline: none
  -webkit-appearance: none
  -moz-appearance: none
  appearance: none
  resize: vertical
  vertical-align: middle
  &:focus
    outline: none

select
  cursor: pointer
  &:disabled
    opacity: 1
    cursor: not-allowed

input,
textarea
  cursor: text
  &:disabled
    opacity: 1
    cursor: not-allowed
    resize: none

input[type="search"]
  -webkit-appearance: textfield
  -webkit-appearance: none
  &:focus
    outline-offset: -2px
    outline: 0
  &::-webkit-search-decoration
    display: none

input[type="submit"],
input[type="reset"],
input[type="button"],
input[type="radio"],
input[type="checkbox"],
button
  cursor: pointer
  padding: 0
  &:focus
    outline: 0
  &:disabled
    cursor: not-allowed

a
  text-decoration: none
  cursor: pointer

img
  vertical-align: middle

// Common style that applies to the whole
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&family=Roboto:wght@400;700&display=swap')

html
  font-size: 62.5%
  scroll-behavior: smooth

:target
  scroll-margin-top: var(--hvh)

body
  font-size: 1.6rem
  font-family: 'Noto Sans JP', 'Roboto', sans-serif
  font-weight: 400
  letter-spacing: .05rem

  input,
  select,
  textarea
    font-family: 'Noto Sans JP', sans-serif

.js-body
  &.-lock
    overflow: hidden
