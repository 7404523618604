.list_unordered
  & > .list
    & > .item
      display: inline-block
      position: relative
      color: $color-text-base
      font-size: 1.6rem
      padding: 4px 2px 3px 18px
      &:before
        position: absolute
        top: 12px
        left: 0
        content: ""
        display: inline-block
        width: 10px
        height: 10px
        border-radius: 50%
        background: $color-maingradation
