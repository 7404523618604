.contact_side
  &:not(:first-child)
    margin-top: 30px
  & > .block
    background-color: #fff
    border-radius: 8px
    padding: 7px
    box-shadow: $shadow-other
    & > .head
      display: flex
      justify-content: center
      align-items: center
      padding: 10px 0
      min-height: 60px
      background: $color-subgradation
      border-radius: 6px 6px 0 0
      +mq()
        min-height: 80px
        border-radius: 6px
      & > .title
        display: inline-block
        font-weight: 700
        font-size: 2rem
        color: $color-text-base
        text-align: center
    & > .content
      padding: 20px 12px
      position: relative
      & > .infobox
        &:not(:first-child)
          margin-top: 30px
        & > .title
          font-size: 1.6rem
          text-align: center
          font-weight: 700
        & > .tel
          margin-top: 12px
          display: flex
          align-items: center
          justify-content: center
          & > .icon
            margin: 0
          & > .text
            font-size: 2.8rem
            text-align: center
            font-weight: 700
            font-family: 'Roboto'
            display: inline-block
            background: $color-maingradation
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent
        & > .infotime
          margin-top: 5px
          text-align: center
          color: $color-text-base
          font-size: 1.4rem
        & > .buttonbox
          margin-top: 15px

