a.item_news
  display: flex
  align-items: flex-start
  flex-wrap: wrap
  padding: 20px 0px
  border-bottom: 1px solid $color-basegray
  background-color: #fff
  +mq()
    flex-wrap: nowrap
  & > .date
    font-size: 1.4rem
    font-weight: 700
    letter-spacing: 0.02rem
    color: $color-text-base
    flex: 0 0 80px
    padding: 5px 0px 7px
  & > .category
    font-size: 1.4rem
    font-weight: 700
    letter-spacing: 0.02rem
    flex: 0 0 150px
    border-radius: 100px
    background-color: $color-subbrand
    color: $color-mainbrand
    padding: 5px 10px 7px
    text-align: center
    margin-left: 20px
  & > .title
    font-size: 1.6rem
    font-weight: 400
    letter-spacing: 0.02rem
    color: $color-text-base
    margin-top: 7px
    +mq()
      padding: 5px 10px 7px 20px
      margin-top: 0
  &:hover
    & > .title
      color: $color-text-link

