.nav_footer
  .navparent
    border-top: 1px solid #fff
    padding: 20px 10px
    position: relative
    +mq()
      border-top: none
      padding: 0 6px
    & > a.link
      display: inline-block
      color: $color-text-base
      font-weight: 700
      font-size: 1.6rem
      +mq()
        display: block
        padding: 6px
        border-bottom: none
      &:hover
        +mq()
          color: $color-text-link
    & > .icon
      position: absolute
      display: block
      top: 0px
      left: 0px
      right: 0px
      bottom: 0px
      border: none
      width: 100%
      height: 100%
      background-color: transparent
      background-repeat: no-repeat
      background-size: 22px
      background-position: 100% center
      padding: 0
      +mq()
        display: none
      &.-close
        background-image: url(@src/img/ic_plus.svg)
      &.-open
        background-image: url(@src/img/ic_minus.svg)
      &.arrow
        background-image: url(@src/img/ic_arrow01.svg)
  & > ul.list
    display: none
    padding: 15px 0 30px
    &.-close
      display: none
      +mq()
        display: block
    &.-open
      display: block
    +mq()
      display: block
      padding: 0
    & > li.item
      padding: 0 6px
      & > a.link
        display: block
        color: $color-text-base
        font-weight: 400
        font-size: 1.6rem
        padding: 6px 6px 6px 16px
        position: relative
        +mq()
          padding: 6px
        &::before
          position: absolute
          top: 7px
          left: 0px
          content: url(@src/img/ic_arrow02.svg)
          display: inline-block
          +mq()
            display: none
        &:hover
          +mq()
            color: $color-text-link
