.wrap_side280
  padding: 60px 19px
  background: $color-subgradation
  // +mq('screen and (min-width:560px) and (max-width:1100px)')
  //   display: none
  &.-sp_hide
    +mq('screen and (max-width:560px)')
      display: none
  +mq()
    width: 280px
    flex: 0 0 280px
    padding: 0.5px 0 100px
    background: transparent
  & > .inner
    +mq()
      position: sticky
      top: calc(#{$height-header} + 20px)
      z-index: calc(#{$zindex-header} - 1)