.button_menu
  border-radius: 4px
  padding: 10px
  width: 36px
  height: 56px
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  & > .button
    display: none
  &.-closs
    & > .button.close
      display: block
  &.-threebar
    & > .button.open
      display: block
