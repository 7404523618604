.wrap_double1100
  padding: 0.5px calc((100% - 1100px)/2)
  +mq()
    display: flex
    justify-content: space-between
  &.-center
    +mq()
      justify-content: center
  // &.-theme-gray
  //   background-color: $color-lightgray
