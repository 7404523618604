.button_simple
  display: inline-block
  line-height: 1
  white-space: nowrap
  outline: none
  border: none
  &.-wide
    display: block
    width: 100%
  &.-solid
    font-weight: 700
    font-size: 1.4rem
    padding: 10px 18px 11px
    border-radius: 100px
    &.-size_s
      font-size: 1.6rem
      padding: 15px 30px 15px
      box-shadow: $shadow-button_s
      line-height: 1
      +mq()
        font-size: 1.4rem
        padding: 14px 18px 15px
        line-height: 1.2
    &.-size_m
      font-size: 1.6rem
      padding: 15px 30px 15px
      box-shadow: $shadow-button_l
      line-height: 1
      +mq()
        font-size: 1.6rem
        padding: 16px 18px 17px
        line-height: 1.2
    &.-size_l
      font-size: 1.6rem
      padding: 15px 30px 15px
      box-shadow: $shadow-button_l
      line-height: 1
      +mq()
        font-size: 1.6rem
        padding: 19px 34px 20px
        line-height: 1.2
    &.-colorlink
      background: $color-maingradation
      text-shadow: 0 1px 4px rgba(darken($color-mainbrand,50%),.16)
      color: #fff
      &:hover
        +mq()
          background: $color-maingradationhover
  &.-flat
    font-weight: 700
    font-size: 1.5rem
    padding: 7px 18px 9px
    border-radius: 4px
    &.-colortextlink
      color: $color-text-link
      background-color: transparent
      &:hover
        +mq()
          background-color: rgba(0,0,0,.02)
  &.-icon
    display: flex
    align-items: center
    justify-content: center
    &::before
      display: inline-block
      content: ''
      margin-right: 6px
      background-size: contain
      background-repeat: no-repeat
    &.-contact::before
      padding-top: 1px
      width: 17px
      height: 17px
      background-image: url('@src/img/ic_w_contact.svg')
    &.-window::before
      padding-top: 1px
      width: 17px
      height: 14px
      background-image: url('@src/img/ic_w_window.svg')
    &.-arrow::before
      width: 22px
      height: 22px
      background-image: url('@src/img/ic_w_arrow01.svg')
    &.-map::before
      width: 20px
      height: 20px
      background-image: url('@src/img/ic_w_map.svg')
