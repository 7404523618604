a.button_banner
  display: flex
  align-items: center
  position: relative
  width: 336px
  height: 80px
  padding: 0 20px
  background-color: #fff
  border-radius: 6px
  overflow: hidden
  box-shadow: $shadow-other
  +mq()
    width: 250px
  &:hover
    & > .img_srcset
      transform: scale(1.2,1.2)
  & > .img_srcset
    position: absolute
    display: block
    width: 100%
    height: 100%
    top: 0
    left: 0
    right: 0
    bottom: 0
    margin: auto
    object-fit: contain
    object-position: 100% 50%
    transition: transform .6s ease
  & > .text
    z-index: 1
    position: relative
    display: flex
    align-items: center
    font-size: 1.6rem
    font-weight: 700
    letter-spacing: 0.02rem
    color: $color-text-base
    &:before
      content: url('@src/img/ic_arrow01.svg')
      width: 22px
      height: 22px
      margin-right: 9px

