header.header_global
  background-color: #fff
  box-shadow: $shadow-header
  padding: 6px 0
  position: sticky
  top: 0
  z-index: $zindex-header
  +mq()
    padding: 15px 0 0px
  & > .wrap_single1440 > .inner
    display: flex
    // align-items: center
    padding: 0 18px
    +mq()
      padding: 0 40px
    & > a.siteid
      padding-top: 2px
      display: block
      +mq()
        padding-top: 7px
        flex: 0 1 265px
        padding-right: 10px
      & > .sitecopy
        font-weight: 700
        font-size: 1.0rem
        letter-spacing: 4%
        white-space: nowrap
        color: $color-text-base
        +mq()
          font-size: 1.4rem
          letter-spacing: 10%
      & > .logo
        margin-top: 5px
        max-width: 138px
        +mq()
          margin-top: 11px
          max-width: 185px
        & > img.logoimage
          width: 100%
          height: auto
      &:hover
        +mq()
          & > .sitecopy,
          & > .logo
            opacity: .75
    & > nav.navgroup
      display: none
      +mq()
        flex: 1 1 100%
        display: block
        // display: flex
        // justify-content: space-between
        // align-items: center
        overflow-x: auto
      & > .subgroup
        display: flex
        justify-content: flex-end
        & > .nav_submenu
          overflow-x: auto
        & > .nav_contact
          margin-left: 12px
          flex: 0 0 auto
          overflow-x: auto
      & > .megamenugroup
        // flex: 1 1 100%
        margin-top: 17px
        display: flex
        overflow-x: auto
        justify-content: flex-end
    & > nav.navgroupsp
      display: block
      margin-left: auto
      +mq()
        display: none
