main.main_global.index
  margin: 0
  & > .wrap_single1100
    padding-top: 60px
    padding-bottom: 60px
    +mq()
      padding-top: 90px
      padding-bottom: 90px
    & > .column3_layout
      &:not(:first-child)
        margin-top: 30px
  & > .visual_top
    position: relative
    background-size: cover
    background-position: center
    background-image: url("@src/img/img_mainvisual_sp.jpg")
    min-height: 472px
    +mq()
      background-image: url("@src/img/img_mainvisual_pc.jpg")
      min-height: 538px
    & > .content
      padding: 55px 25px
      +mq()
        max-width: 1000px
        padding: 100px 0
        margin: 0 auto
      & > .title
        color: $color-mainbrand
        font-weight: 700
        font-size: 3rem
        line-height: 1.4
        +mq()
          font-size: 4.2rem
          line-height: normal
      & > .text
        margin-top: 12px
        color: $color-text-base
        font-weight: 400
        font-size: 1.5rem
        line-height: 2.4rem
        +mq()
          margin-top: 20px
          font-size: 1.8rem
          line-height: 3.2rem
      & > .linkarea
        margin-top: 20px
        +mq()
          margin-top: 30px
        & > .link
          position: relative
          display: inline-block
          padding: 0 0 0 27px
          color: $color-text-base
          font-size: 1.5rem
          +mq()
            font-size: 1.8rem
          &:hover
            color: $color-text-link
          &::before
            position: absolute
            left: 0
            content: ''
            width: 22px
            height: 22px
            background-image: url(@src/img/ic_arrow01.svg)
            background-size: contain
            background-repeat: no-repeat
            top: 1px
            +mq()
              top: 3px
    & > .fixedbox
      display: none
      +mq()
        display: block
        position: absolute
        right: 0
        bottom: -30px
      & > .block
        background-color: #fff
        border-radius: 8px 0 0 8px
        box-shadow: $shadow-other
        & > .head
          padding: 12px 0
          text-align: center
          background-color: $color-mainbrand
          border-radius: 8px 0 0 0
          & > .title
            display: inline-block
            font-weight: 700
            font-size: 1.8rem
            color: #fff
        & > .content
          padding: 10px
          position: relative
          +mq()
            padding: 15px 17px
          & > .dl
            display: flex
            padding: 5px 0
            align-items: stretch
            & > .dt
              flex: 0 0 85px
              background-color: $color-subbrand
              color: $color-mainbrand
              font-weight: 700
              font-size: 1.6rem
              display: flex
              justify-content: center
              align-items: center
              padding: 6px 0
            & > .dd
              flex: 1 1 auto
              display: flex
              justify-content: flex-start
              align-items: center
              font-weight: 700
              font-size: 1.6rem
              padding-left: 16px
              .textright
                text-align: right
          & > .text
            font-size: 1.2rem
            line-height: 1.8rem
            color: $color-text-base
            padding: 5px 0
            & > a
              color: $color-text-link
              text-decoration: underline
  & > .first_information
    margin-top: -120px
    position: relative
    +mq()
      margin-top: unset
    & > .column3_layout
      & > .column
        & > .item_firstinfo
          & > .block
            padding: 7px
            border-radius: 8px
            background-color: #fff
            box-shadow: $shadow-other
            & > .head
              border-radius: 6px 6px 0px 0px
              background: $color-subgradation
              height: 110px
              display: flex
              flex-direction: column
              justify-content: center
              align-items: center
              +mq()
                height: 150px
              & > .iconbox
                width: 60px
                height: 60px
                display: flex
                align-items: center
                +mq()
                  width: 80px
                  height: 80px
                & > img.icon
                  display: block
                  width: 100%
              & > .title
                display: block
                font-size: 2rem
                color: $color-text-base
                font-weight: 700
                margin-top: 0
                +mq()
                  margin-top: 10px
            & > .content
              & > .linklist
                padding: 20px 15px 20px
                & > .link
                  position: relative
                  display: inline-block
                  padding: 0 0 0 27px
                  color: $color-text-base
                  font-size: 1.6rem
                  display: block
                  &:not(:first-child)
                    margin-top: 14px
                  &:hover
                    color: $color-text-link
                  &::before
                    position: absolute
                    left: 0
                    top: 2px
                    content: ''
                    width: 22px
                    height: 22px
                    background-image: url(@src/img/ic_arrow01.svg)
                    background-size: contain
                    background-repeat: no-repeat
  & > .wrap_single1100
    & > .newstablist_top
      margin-top: 40px
      +mq()
        margin-top: 30px
  & > .recruit_information
    background-color: $color-subbrand
    background-repeat: no-repeat
    background-position: 100% 100%
    background-size: contain
    min-height: 244px
    background-image: url("@src/img/img_recruit_top_sp.png")
    +mq()
      min-height: 465px
      background-image: url("@src/img/img_recruit_top_pc.png")
    & > .wrap_single1100
      & > .block
        padding-top: 55px
        +mq()
          padding-top: 90px
        & > .title_top
          margin: 0
        & > .content
          margin-top: 15px
          +mq()
            margin-top: 30px
            max-width: 590px
          & > .text
            font-size: 1.6rem
            line-height: 1.9
            color: $color-text-base
        & > .buttonbox
          margin-top: 15px
          padding-bottom: 70px
          display: flex
          flex-direction: column
          align-items: flex-start
          +mq()
            margin-top: 35px
            padding-bottom: unset
            flex-direction: row
            align-items: unset
          .button_simple
            +mq()
              flex: 0 1 auto
            &:not(:first-child)
              margin-top: 10px
              +mq()
                margin-top: 0
                margin-left: 20px
