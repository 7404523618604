.wysiwyg_editor
  margin-top: 40px
  +mq()
    margin-top: 0
  h2
    background-color: #fff
    box-shadow: $shadow-other
    position: relative
    border-radius: 6px
    font-weight: 700
    font-size: 2.0rem
    padding: 15px 15px 15px 29px
    margin-bottom: 30px
    +mq()
      font-size: 2.6rem
      padding: 15px 15px 15px 37px
      margin-bottom: 40px
    &:not(:first-child)
      margin-top: 55px
      +mq()
        margin-top: 90px
    &::before
      position: absolute
      top: calc(50% - 13px)
      left: 15px
      content: ''
      height: 26px
      width: 4px
      border-radius: 100px
      background-color: $color-mainbrand
      +mq()
        left: 20px
  h3
    background-color: $color-subbrand
    border-radius: 4px
    font-weight: 700
    font-size: 1.8rem
    padding: 7px 12px
    +mq()
      font-size: 2.0rem
      padding: 6px 15px
    &:not(:first-child)
      margin-top: 30px
      +mq()
        margin-top: 40px
  h4
    font-size: 1.6rem
    font-weight: 700
    line-height: 1.75
    &:not(:first-child)
      margin-top: 20px
  p
    font-size: 1.6rem
    font-weight: 400
    line-height: 1.75
    &:not(:first-child)
      margin-top: 20px
    &.-center
      text-align: center
    &.-attention
      color: $color-red
  img
    display: block
    width: 100%
    +mq()
      display: inline
      width: unset
  ol
    list-style-type: decimal
    padding-left: 20px
    &:not(:first-child)
      margin-top: 20px
    li
      font-size: 1.6rem
      font-weight: 400
      line-height: 1.75
      &:not(:first-child)
        margin-top: 7px
  ul.type01
    &:not(:first-child)
      margin-top: 20px
    li
      display: block
      position: relative
      color: $color-text-base
      font-size: 1.6rem
      line-height: 2
      padding: 4px 2px 3px 18px
      &:before
        position: absolute
        top: 16px
        left: 0
        content: ""
        display: inline-block
        width: 10px
        height: 10px
        border-radius: 50%
        background: $color-maingradation
  ul.type02
    padding-left: 20px
    &:not(:first-child)
      margin-top: 15px
    li
      font-size: 1.4rem
      font-weight: 400
      line-height: 1.5
      +mq()
        font-size: 1.6rem
      &:not(:first-child)
        margin-top: 5px
      &::before
        content: "※"
        display: inline-block
        margin-left: -20px
        margin-right: 5px
  .wys_buttonbox
    display: flex
    flex-direction: column
    align-items: flex-start
    &:not(:first-child)
      margin-top: 20px
    &.-center
      align-items: center
      justify-content: center
    +mq()
      margin-top: 35px
      padding-bottom: unset
      flex-direction: row
      align-items: unset
    .button_simple
      +mq()
        flex: 0 1 auto
      &:not(:first-child)
        margin-top: 10px
        +mq()
          margin-top: 0
          margin-left: 10px
  .wys_imagebox
    margin-top: 20px
    +mq()
      display: flex
      justify-content: flex-start
      flex-direction: row-reverse
    & > .img_srcset
      display: block
      width: 100%
      +mq()
        flex: 0 0 200px
        width: 200px
    & > p
      margin-top: 10px
      +mq()
        flex: 1 1 auto
        margin-top: 0
        margin-right: 50px
  .wys_mapbox
    margin-top: 30px
    +mq()
      margin-top: 40px
    & > .inner
      iframe
        width: 100%
        height: auto
        +mq()
          height: 382px
      & > .mapinfo
        margin-top: 20px
        +mq()
          display: flex
          justify-content: flex-start
          flex-direction: row
        & > p
          margin-top: 10px
          +mq()
            flex: 1 1 auto
            margin-top: 0
            margin-right: 50px
        & > .buttonbox
          margin-top: 20px
          display: block
          width: 100%
          +mq()
            margin-top: 0
            flex: 0 0 200px
            width: 200px
  .wys_twoblock
    margin-top: 20px
    display: flex
    flex-direction: column
    +mq()
      flex-direction: row
    .block
      flex: 1 1 auto
      +mq()
        flex: 0 0 50%
  .wys_targetlink
    margin-top: 10px
    &.-right
      text-align: right
    a.link
      display: inline-block
      font-size: 1.6rem
      color: $color-text-link
      position: relative
      padding-right: 20px
      &::after
        position: absolute
        top: 8px
        right: 0
        content: ""
        display: inline-block
        width: 12.5px
        height: 10px
        background-image: url("@src/img/ic_window.svg")
        background-repeat: no-repeat
        background-size: contain
      &:hover
        text-decoration: underline
  table
    width: 100%
    border-top: 1px solid $color-basegray
    border-bottom: 1px solid $color-basegray
    &:not(:first-child)
      margin-top: 30px
    &.-headsize_xs
      tbody > tr > th
        width: 90px
        white-space: normal
      tbody > tr > td
        width: calc(100% - 90px)
    &.-headsize_s
      tbody > tr > th
        white-space: normal
        width: 120px
        +mq()
          width: 170px
      tbody > tr > td
        width: calc(100% - 170px)
    &.-headsize_m
      tbody > tr > th
        width: 220px
        white-space: normal
      tbody > tr > td
        width: calc(100% - 220px)
    &.-headsize_l
      tbody > tr > th
        width: 140px
        white-space: normal
        +mq()
          width: 270px
      tbody > tr > td
        width: calc(100% - 270px)
    &.-sp_stepdown
      +mq('screen and (max-width:560px)')
        display: block
        tbody
          display: block
          tr
            display: block
            padding: 15px 0
            th
              display: block
              width: 100%
              padding: 5px
            td
              display: block
              width: 100%
              padding: 5px
    tbody
      tr
        &:not(:first-child)
          border-top: 1px solid $color-basegray
        th
          padding: 20px 10px
          font-size: 1.6rem
          font-weight: 700
          line-height: 1.75
          white-space: nowrap
          text-align: left
          +mq()
            padding: 30px 10px
        td
          padding: 20px 5px 20px 10px
          font-size: 1.6rem
          font-weight: 400
          line-height: 1.75
          +mq()
            padding: 30px 5px 30px 10px

  .wys_tablewrap
    width: calc(100% + 40px)
    margin: 0 -20px
    padding: 0 20px
    overflow-x: scroll
    &:not(:first-child)
      margin-top: 30px
    table
      width: 100%
      min-width: 750px
      &.-theadthin > thead > tr > th
        padding-top: 8px
        padding-bottom: 8px
        line-height: 1.3
      &.-tbodythleft > tbody > tr > th
        text-align: left
      &.-tdcenter > tbody > tr > td
        text-align: center
      &.-tdnarrow > tbody > tr > td
        padding-left: 5px
        padding-right: 5px
      thead
        tr
          background-color: $color-subbrand
          th
            padding: 16px 10px
            font-size: 1.6rem
            font-weight: 700
            line-height: 1.75
            white-space: nowrap
            vertical-align: middle
            color: $color-mainbrand
            border: 2px solid $color-subbrand2
      tbody
        tr
          th
            padding: 12px 10px
            font-size: 1.4rem
            font-weight: 700
            line-height: 1.75
            white-space: nowrap
            text-align: center
            vertical-align: middle
            border: 2px solid $color-subbrand2
          td
            padding: 12px 15px
            font-size: 1.4rem
            font-weight: 400
            line-height: 1.75
            vertical-align: middle
            border: 2px solid $color-subbrand2
            &.-center
              text-align: center
              padding-left: 5px
              padding-right: 5px

  & > .list_index
    & > .item_index
      &:not(:first-child)
        margin-top: 30px


  // dl
  //   display: flex
  //   border-top: 1px solid $color-basegray
  //   border-bottom: 1px solid $color-basegray
  //   padding: 10px 0
  //   &:not(:first-child)
  //     margin-top: 30px
  //   dt
  //     padding: 10px
  //     font-size: 1.6rem
  //     font-weight: 700
  //     line-height: 1.75
  //     flex: 0 0 130px
  //   dd
  //     padding: 10px 5px
  //     font-size: 1.6rem
  //     font-weight: 400
  //     line-height: 1.75
  //     flex: 1 1 auto
  // dl + dl
  //   border-top: none
  //   &:not(:first-child)
  //     margin-top: 0
