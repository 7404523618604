.nav_submenu
  & > ul.list
    display: flex
    align-items: center
    padding: 0 8px
    background-color: $color-subbrand
    border-radius: 4px
    & > li.item
      flex: 0 0 auto
      padding: 0 6px
      & > a.link
        display: flex
        align-items: center
        color: $color-text-base
        font-weight: 700
        font-size: 1.4rem
        padding: 9px 3px
        &:hover
          +mq()
            color: $color-text-link
        &::before
          display: block
          content: ''
          width: 17px
          height: 17px
          margin-right: 4px
          object-fit: contain
        &.news::before
          content: url('@src/img/ic_news.svg')
        &.about_access::before
          content: url('@src/img/ic_access.svg')
        &.outpatient_medical::before
          content: url('@src/img/ic_relationship.svg')
