.column4_layout
  display: flex
  flex-direction: column
  overflow-x: auto
  width: 100%
  justify-content: space-between
  +mq()
    flex-direction: row
  & > .column
    padding: 0 12px
    &:not(:first-child)
      margin-top: 30px
    +mq()
      flex: 0 0 250px
      &:not(:first-child)
        margin-top: unset
