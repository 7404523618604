.column3_layout
  display: flex
  flex-direction: column
  // overflow-x: auto
  width: 100%
  justify-content: space-between
  +mq()
    flex-direction: row
  & > .column
    &:not(:first-child)
      margin-top: 20px
      +mq()
        margin-top: unset
    +mq()
      flex: 0 0 340px
