.item_dummy
  background-color: #ffff00
  border: 2px solid #f5f100
  border-radius: 6px
  max-height: 300px
  overflow: hidden
  position: relative
  @for $num from 1 through 100
    &.-h#{$num * 10}px
      max-height: #{$num * 10}px
      height: #{$num * 10}px
  &.-pink
    background-color: #ffddf7
    border: 2px solid #f500d4
  .inner
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    position: absolute
    margin: auto
    top: 0
    left: 0
    right: 0
    bottom: 0
    width: 100%
    height: 100%
    padding: 0 10px
    & > p.title
      color: $color-text-base
      font-size: 1.8rem
      font-weight: 700
    & > p.text
      color: $color-text-base
      font-size: 1.3rem
      margin-top: 6px
    & > .class
      color: $color-text-base
      background-color: #f5f5f5
      display: inline-block
      font-size: 1.1rem
      padding: 1.5px 4px 3px
      border-radius: 4px
      border: 1px solid #f0f0f0
  &::after
    content: ''
    display: block
    padding-top: 100%

picture.item_dummyimg
  & > .dummyimg
    width: 100%
