.item_doctor
  padding: 30px 10px
  border-top: 1px solid $color-basegray
  border-bottom: 1px solid $color-basegray
  &:not(:first-child)
    border-top: none
  .inner
    display: flex
    flex-direction: column
    +mq()
      flex-direction: row
    .doctor
      +mq()
        flex: 0 0 50%
      .name
        font-size: 2.0rem
        font-weight: 700
        color: $color-text-base
      .kana
        margin-top: 6px
        font-size: 1.4rem
        font-weight: 700
        color: $color-text-base
        +mq()
          font-size: 1.6rem
      .career
        margin-top: 15px
        font-size: 1.4rem
        font-weight: 400
        color: #888
        +mq()
          font-size: 1.6rem
      .title
        margin-top: 18px
        font-size: 1.4rem
        font-weight: 700
        color: $color-text-base
        line-height: 1.6
        +mq()
          font-size: 1.6rem
          line-height: 1.8
    .categories
      margin-top: 18px
      +mq()
        margin-top: 0
        margin-left: 40px
        flex: 0 0 calc(50% - 40px)
      .item
        display: flex
        align-items: flex-start
        &:not(:first-child)
          margin-top: 15px
        .label
          font-size: 1.4rem
          font-weight: 700
          color: $color-text-base
          border-radius: 4px
          padding: 10px 12px
          background-color: $color-subbrand
          +mq()
            font-size: 1.6rem
        .text
          padding-left: 20px
          font-size: 1.4rem
          font-weight: 400
          color: $color-text-base
          line-height: 1.6
          +mq()
            font-size: 1.6rem
            line-height: 1.8
