.form_contact
  .formblock
    margin-top: 30px
    padding: 20px
    background-color: #fff
    box-shadow: $shadow-other
    border-radius: 8px
    +mq()
      padding: 40px
    & > .formitem
      display: flex
      flex-direction: column
      +mq()
        flex-direction: row
      &:not(:first-child)
        margin-top: 7px
        +mq()
          margin-top: 20px
      & > .formlabel
        +mq()
          flex: 0 0 220px
        & > .label
          font-size: 1.6rem
          font-weight: 700
          color: $color-text-base
          display: block
          padding: 9px 0
          & > .require
            margin-left: 3px
            display: inline-block
            font-size: 1.2rem
            color: $color-mainbrand
      & > .formfield
        flex: 1 1 auto
        & > .input
          background-color: #f9f9f9
          border: 1px solid $color-basegray
          border-radius: 6px
          font-size: 1.6rem
          padding: 15px 10px 15px
          width: 100%
          &::placeholder
            color: #c5c5c5
          &.-confirm
            border: none
            background-color: #fafafa
        & > .textarea
          background-color: #f9f9f9
          border: 1px solid $color-basegray
          border-radius: 6px
          font-size: 1.6rem
          line-height: 1.6
          padding: 8px 10px 7px
          width: 100%
          height: calc(1.6rem * 1.6 * 6)
          &::placeholder
            color: #c5c5c5
          &.-confirm
            border: none
            background-color: #fafafa
        & > .checkitem
          cursor: pointer
          line-height: 1
          padding: 6px 0px
          display: block
          &.disabled
            cursor: not-allowed
          &.-confirm
            padding: 15px 10px
            background-color: #fafafa
            border-radius: 6px
          & > .filed
            background-color: #FAFAFA
            border: 1px solid #E5E5E5
            width: 20px
            height: 20px
            border-radius: 4px
            margin: 0 7px 0 0
            display: inline-block
            position: relative
            vertical-align: middle
            &:checked
              &::before
                content: url('@src/img/ic_checkbox.svg')
                display: inline-block
                position: absolute
                top: calc(50% - 4px)
                left: calc(50% - 6px)
                width: 12px
                height: 9px
                font-size: 1.0rem
                line-height: 1
                vertical-align: middle
            &:disabled
              background-color: $color-lightgray
              border-color: $color-lightgray
              &:checked + .text
                color: lighten($color-text-base,10%)
              &:not(:checked) + .text
                color: $color-basegray
          & > .text
            font-size: 1.6rem
            color: $color-text-base
            white-space: nowrap
            vertical-align: middle
  .agreecheck
    margin-top: 25px
    display: flex
    +mq()
      justify-content: center
    & > .label
      & > .filed
        background-color: #FAFAFA
        border: 1px solid #E5E5E5
        width: 20px
        height: 20px
        border-radius: 4px
        margin: 0 7px 0 0
        display: inline-block
        position: relative
        vertical-align: middle
        &:checked
          &::before
            content: url('@src/img/ic_checkbox.svg')
            display: inline-block
            position: absolute
            top: calc(50% - 4px)
            left: calc(50% - 6px)
            width: 12px
            height: 9px
            font-size: 1.0rem
            line-height: 1
            vertical-align: middle
        &:disabled
          background-color: $color-lightgray
          border-color: $color-lightgray
          &:checked + .text
            color: lighten($color-text-base,10%)
          &:not(:checked) + .text
            color: $color-basegray
    & > .text
      font-size: 1.6rem
      color: $color-text-base
      vertical-align: middle
      & > .link
        color: $color-text-link
        text-decoration: underline
  .buttonbox
    margin-top: 40px
    display: flex
    +mq()
      margin-top: 20px
    &.-center
      justify-content: center
      align-items: center

