footer.footer_global
  background-color: $color-subbrand
  padding: 0px
  +mq()
    padding: 84px 0 0
  & > .wrap_single1100 > .inner
    display: block
    +mq()
      display: flex
      padding: 0 0px 65px
    & > .navhead
      padding-top: 50px
      padding-bottom: 45px
      +mq()
        padding-top: 0px
        padding-bottom: 0px
        flex: 0 1 430px
        min-width: 330px
      & > a.siteid
        text-align: center
        +mq()
          text-align: left
        & > .sitecopy
          padding-top: 6px
          font-weight: 700
          font-size: 1.0rem
          letter-spacing: 4%
          white-space: nowrap
          color: $color-text-base
          +mq()
            font-size: 1.4rem
            letter-spacing: 10%
        & > .logo
          display: block
          margin: 0 auto
          max-width: 200px
          +mq()
            margin: 0
          & > img.logoimage
            width: 100%
            margin-top: 7px
            +mq()
              margin-top: 15px
        &:hover
          & > .sitecopy
            +mq()
              opacity: .75
          & > .logo
            & > img.logoimage
              +mq()
                opacity: .75
      & > .hospitalinfo
        margin-top: 14px
        text-align: center
        +mq()
          margin-top: 18px
          text-align: left
        & > .address,
        & > .tel
          font-weight: 400
          font-size: 1.5rem
          line-height: 26px
          +mq()
            font-size: 1.6rem
            line-height: 32px
          & > a.link
            display: inline-block
            font-weight: bold
            font-size: 1.3rem
            padding: 6px 0
            color: $color-text-link
            &:hover
              text-decoration: underline
          br.pc_hide
            +mq()
              display: none

    & > nav.navgroup
      flex: 1 1 100%
      overflow-x: auto
      border-bottom: solid 1px #fff
      +mq()
        display: flex
        justify-content: flex-end
        border-bottom: 0
      & > .block:first-child
        & > .nav_footer
          & > .navparent
            border-top: 1px solid #fff
            +mq()
              border-top: none
      & > .block:not(:first-child)
        +mq()
          padding-left: 65px
      & > .block
        & > .nav_footer:not(:first-child)
          +mq()
            margin-top: 30px
  & > .copyright
    display: flex
    background-color: #fff
    justify-content: center
    flex-wrap: wrap
    padding: 0 0 10px
    +mq()
      padding: 20px 40px
      flex-direction: row-reverse
      justify-content: space-between
      align-items: center
    & > .navsubfooter
      & > .list
        display: flex
        +mq()
          padding: 0
        & > .item
          padding: 18px 14px
          +mq()
            padding: 0 6px
          & > .link
            display: block
            color: $color-text-base
            font-weight: 400
            font-size: 1.2rem
            padding: 6px 6px 6px 16px
            position: relative
            +mq()
              padding: 6px
            &::before
              position: absolute
              top: 7px
              left: 0px
              content: url(@src/img/ic_arrow02.svg)
              display: inline-block
              +mq()
                display: none
            &:hover
              +mq()
                color: $color-text-link
    small.text
      display: inline-block
      line-height: 1
      vertical-align: middle
      font-size: 1.2rem
      color: $color-text-base
      padding-top: 5px
      &::before
        content: '©'
        display: inline-block
        padding-right: 2px
        vertical-align: middle
        font-size: 1.8rem
        line-height: 1
        transform: translateY(-2.3px)
