+reset-wysiwyg('.item_index')
.item_index
  display: block
  border-radius: 6px
  overflow: hidden
  position: relative
  box-shadow: $shadow-other
  color: $color-text-base
  & > .inner
    +mq()
      display: flex
      align-items: center
    & > picture.wrapimg
      +mq()
        flex: 0 0 240px
        overflow: hidden
      & > .img
        width: 100%
        transition: transform .6s ease
    & > .content
      padding: 20px
      +mq()
        padding-left: 40px
      & > .title
        display: block
        color: $color-text-base
        font-size: 2rem
        font-weight: 700
        position: relative
        padding: 0 0 0 32px
        &::before
          position: absolute
          top: 5px
          left: 0
          content: ''
          background-image: url(@src/img/ic_arrow01.svg)
          background-repeat: no-repeat
          background-size: contain
          width: 22px
          height: 22px
      & > p.text
        color: $color-text-base
        font-size: 1.6rem
        margin-top: 15px
        line-height: 1.8
        +mq()
          line-height: 1.8
  &:hover
    // background-color: #fafafa
    & > .inner
      & > picture.wrapimg
        & > .img
          transform: scale(1.2,1.2)
      & > .content
        & > .title
          color: $color-text-link
        & > p.text
          color: $color-text-link
