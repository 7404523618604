.header_subpage
  background: $color-subgradation
  & > .inner
    // position: relative
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    height: 160px
    background-position: 100% center
    background-size: contain
    background-repeat: no-repeat
    +mq()
      height: 280px
    & > img.icon
      display: block
      width: 44px
      +mq()
        width: 80px
        height: 80px
    & > .parent
      display: flex
      color: $color-mainbrand
      font-size: 1.4rem
      font-weight: 700
      +mq()
        font-size: 1.8rem
    & > .title
      padding: 8px 12px 0
      font-size: 2.4rem
      font-weight: 700
      color: $color-text-base
      +mq()
        font-size: 3.4rem
        padding: 0px 40px 0
        margin-top: 10px
    & > .text
      display: none
      +mq()
        display: block
        font-size: 1.6rem
        font-weight: 700
        color: $color-text-base
        margin-top: 6px
  &.-nurse
    & > .inner
      background-image: url("@src/img/subpage_header_nurse_sp.png")
      +mq()
        background-image: url("@src/img/subpage_header_nurse_pc.png")
  &.-ichimi
    & > .inner
      background-image: url("@src/img/subpage_header_ichimi_sp.png")
      +mq()
        background-image: url("@src/img/subpage_header_ichimi_pc.png")
  &.-ootsuka
    & > .inner
      background-image: url("@src/img/subpage_header_ootsuka_sp.png")
      +mq()
        background-image: url("@src/img/subpage_header_ootsuka_pc.png")
  &.-ohana
    & > .inner
      background-image: url("@src/img/subpage_header_ohana_sp.png")
      +mq()
        background-image: url("@src/img/subpage_header_ohana_pc.png")
